import moment from "moment";

export default {
    install(Vue){
        Vue.prototype.$getDateQuarter = function () {
            let date = new Date();
            let month = moment().format("MM-");
            let year = moment().format("-YYYY");
            let currentDate = moment().format("DD");
            let midpointOfMonth = new Date(date.getFullYear(), date.getMonth(), 15);
            midpointOfMonth = moment(midpointOfMonth).format("DD");
            let firstDayOfSecondHalf = Number(midpointOfMonth) + 1;
            let lastDayOfSecondHalf = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
            );
            lastDayOfSecondHalf = moment(lastDayOfSecondHalf).format("DD");
            if (currentDate >= 1 && currentDate <= midpointOfMonth) {
                return {
                    startDate: moment(month + "01" + year, "MM-DD-YYYY").format(
                      "MM-DD-YYYY"
                    ),
                    endDate: moment(
                      month + midpointOfMonth + year,
                      "MM-DD-YYYY"
                    ).format("MM-DD-YYYY"),
                  }
            }else if(currentDate >= firstDayOfSecondHalf && currentDate <= lastDayOfSecondHalf){
                return {
                    startDate: moment(
                      month + firstDayOfSecondHalf + year,
                      "MM-DD-YYYY"
                    ).format("MM-DD-YYYY"),
                    endDate: moment(
                      month + lastDayOfSecondHalf + year,
                      "MM-DD-YYYY"
                    ).format("MM-DD-YYYY"),
                  }
            }
        }
    }
}